import ImgOne from '../assets/images/gallery/image-1.png'
import ImgTwo from '../assets/images/gallery/image-2.jpg'
import ImgThree from '../assets/images/gallery/image-3.jpg'
import ImgFour from '../assets/images/gallery/image-4.jpg'
import ImgFive from '../assets/images/gallery/image-5.png'
import ImgSix from '../assets/images/gallery/image-6.png'
import ImgSeven from '../assets/images/gallery/image-7.png'
import ImgEight from '../assets/images/gallery/image-8.png'
import ImgNine from '../assets/images/gallery/image-9.png'
import ImgTen from '../assets/images/gallery/image-10.png'
import ImgEleven from '../assets/images/gallery/image-11.png'
import ImgTwelve from '../assets/images/gallery/image-12.png'
import ImgThirteen from '../assets/images/gallery/image-13.png'
import ImgFourteen from '../assets/images/gallery/image-14.png'
import ImgFifteen from '../assets/images/gallery/image-15.png'

const GalleryData = [
  { id: 1, src: ImgOne, alt: 'Georgia' },
  { id: 2, src: ImgTwo, alt: 'Georgia' },
  { id: 3, src: ImgThree, alt: 'Georgia' },
  { id: 4, src: ImgFour, alt: 'Georgia' },
  { id: 5, src: ImgFive, alt: 'Georgia' },
  { id: 6, src: ImgSix, alt: 'Georgia' },
  { id: 7, src: ImgSeven, alt: 'Georgia' },
  { id: 8, src: ImgEight, alt: 'Georgia' },
  { id: 9, src: ImgNine, alt: 'Georgia' },
  { id: 10, src: ImgTen, alt: 'Georgia' },
  { id: 11, src: ImgEleven, alt: 'Georgia' },
  { id: 12, src: ImgTwelve, alt: 'Georgia' },
  { id: 13, src: ImgThirteen, alt: 'Georgia' },
  { id: 14, src: ImgFourteen, alt: 'Georgia' },
  { id: 15, src: ImgFifteen, alt: 'Georgia' },
];

export default GalleryData;
